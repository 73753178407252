import React from "react"
import { Box, Button } from "grommet"
import { useAuth0, useLocation } from "../../hooks"
const Container = () => {
  const auth0 = useAuth0()
  const pageLocation = useLocation()
  const handleLogout = () => {
    auth0.logout({
      returnTo: pageLocation.location.origin,
    })
  }

  return (
    <Box pad="medium">
      <Button onClick={handleLogout} label="Logout"></Button>
      <Box>
        <pre>{JSON.stringify(auth0.user, null, 2)}</pre>
      </Box>
    </Box>
  )
}

export default Container
